import React, { Fragment } from 'react';
import { Flex, Image } from 'rebass/styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import ReactMarkdown from 'react-markdown';
import StyledRectangle from '../components/StyledRectangle';
import CardSubtitle from '../components/CardSubtitle';
import CardText from '../components/CardText';
import Line from '../components/Line';
import MarkdownLinkRenderer from '../components/MarkdownLinkRenderer';

import circuitRight from '../../assets/images/circuitRight@1.5x.svg';
import circuitLeft from '../../assets/images/circuitLeft@1.5x.svg';
import circuitBend from '../../assets/images/circuitBend@1.5x.svg';

const AboutTheQuiz = () => {
  const data_content = useStaticQuery(
    graphql`
      query MyQueryAboutTheQuiz {
        contentfulAoNLandingPageAboutTheQuiz {
          id
          title
          content {
            content
          }
        }
      }
    `
  );

  return (
    <Fragment>
      <Flex flexDirection="column" width="100%" mt={5}>
        <Flex justifyContent="space-between">
          <Image src={circuitLeft} height="80px" alt="circuit-left"></Image>
          <Image src={circuitRight} height="59px" alt="circuit-right"></Image>
        </Flex>
      </Flex>
      <Flex
        flexDirection="column"
        maxWidth="550px"
        justifyContent="center"
        alignContent="center"
        my={10}
      >
        <StyledRectangle bg="red" px={5} py={5}>
          <CardSubtitle color="#f8f9f3">
            {data_content.contentfulAoNLandingPageAboutTheQuiz.title}
          </CardSubtitle>
          <Line height="3px" color="#f8f9f3" my={1} />
          <CardText my={7} color="#2f261e" className="atq">
            <ReactMarkdown
              source={
                data_content.contentfulAoNLandingPageAboutTheQuiz.content
                  .content
              }
              renderers={{ link: MarkdownLinkRenderer }}
              className="linksWhite"
            />
          </CardText>
        </StyledRectangle>
      </Flex>
      <Flex flexDirection="column" width="100%">
        <Flex justifyContent="flex-end">
          <Image src={circuitBend} height="85px" alt="circuit-bend"></Image>
        </Flex>
      </Flex>
    </Fragment>
  );
};

export default AboutTheQuiz;

import React, { Fragment } from 'react';
import { Flex, Image } from 'rebass/styled-components';
import { StaticQuery, graphql } from 'gatsby';
import Dropdown from '../components/Dropdown';
import StyledRectangle from '../components/StyledRectangle';
import CardSubtitle from '../components/CardSubtitle';
import Line from '../components/Line';

import circuitRed from '../../assets/images/circuitRed@1.5x.svg';

const GettingDiagnosedWith = () => (
  <StaticQuery
    query={graphql`
      query MyQueryGettingDiagnosedWith {
        contentfulAoNLandingPageGettingDiagnosedWith {
          id
          title
          diseases {
            id
            disease
            description {
              description
            }
            diseaseIcon {
              file {
                url
              }
            }
          }
        }
      }
    `}
    render={data => {
      const data_content = data.contentfulAoNLandingPageGettingDiagnosedWith;

      return (
        <Fragment>
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignContent="center"
            alignItems="center"
            maxWidth="550px"
            width="100%"
          >
            <StyledRectangle bg="white" width={1} px={5} py={5}>
              <CardSubtitle>{data_content.title}</CardSubtitle>
              <Line height="3px" color="red" my={1} />
            </StyledRectangle>
            {data_content.diseases.map(element => (
              <StyledRectangle bg="white" width={1} my={2} key={element.id}>
                <Dropdown
                  question={element.disease}
                  answer={element.description.description}
                />
              </StyledRectangle>
            ))}
          </Flex>

          <Flex flexDirection="column" width="100%">
            <Flex justifyContent="flex-end" my={13}>
              <Image src={circuitRed} height="68px" alt="circuit-red"></Image>
            </Flex>
          </Flex>
        </Fragment>
      );
    }}
  />
);

export default GettingDiagnosedWith;

import React from 'react';
import { Flex } from 'rebass/styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import ReactMarkdown from 'react-markdown';
import StyledRectangle from '../components/StyledRectangle';
import CardSubtitle from '../components/CardSubtitle';
import CardText from '../components/CardText';
import Line from '../components/Line';
import Dropdown from '../components/Dropdown';
import MarkdownLinkRenderer from '../components/MarkdownLinkRenderer';

const Challenges = () => {
  const data_content = useStaticQuery(
    graphql`
      query MyQueryChallenges {
        contentfulAoNLandingPageChallenges {
          title
          content {
            content
          }
          questions {
            id
            question
            childContentfulChallengesQuestionsAnswerTextNode {
              answer
            }
          }
        }
      }
    `
  );

  return (
    <Flex
      flexDirection="column"
      justifyContent="center"
      alignContent="center"
      alignItems="center"
      maxWidth="550px"
    >
      <StyledRectangle bg="white" px={5} py={6}>
        <CardSubtitle color="#2f261e">
          {data_content.contentfulAoNLandingPageChallenges.title}
        </CardSubtitle>
        <Line height="3px" color="red" my={1} />
        <CardText color="#2f261e" my={7}>
          <ReactMarkdown
            source={
              data_content.contentfulAoNLandingPageChallenges.content.content
            }
            renderers={{ link: MarkdownLinkRenderer }}
          />
        </CardText>
      </StyledRectangle>
      <Line height="1px" color="#dbe2e7" />
      {data_content.contentfulAoNLandingPageChallenges.questions.map(
        element => (
          <StyledRectangle bg="white" my={2} key={element.id}>
            <Dropdown
              question={element.question}
              answer={
                element.childContentfulChallengesQuestionsAnswerTextNode.answer
              }
            ></Dropdown>
          </StyledRectangle>
        )
      )}
    </Flex>
  );
};

export default Challenges;

import React, { useState } from 'react';
import { Text, Heading, Flex, Box, Image } from 'rebass/styled-components';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';
import MarkdownLinkRenderer from '../components/MarkdownLinkRenderer';

import chevronDown from '../../assets/images/chevron-down.svg';

const StyledQuestion = styled(Heading)`
  font-family: Poppins;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.7px;
  color: #ff5e63;
`;

const StyledText = styled(Text)`
  font-size: 16px;
  line-height: 1.5;
  color: #161f26;
`;

const StyledBox = styled(Box)`
  line-height: 1.31;
  letter-spacing: 0.4px;
  color: #161f26;
  border-radius: 9px;
  padding: 15px 20px 20px 20px;
  margin: 0px 0px;
`;

const Dropdown = ({ question, answer }) => {
  const [display, setDisplay] = useState(true);

  const handleDisplay = () => {
    setDisplay(!display);
  };

  return (
    <StyledBox width={1}>
      <Flex
        justifyContent="space-between"
        alignItems="flex-start"
        pt={2}
        onClick={handleDisplay}
        style={{
          cursor: 'pointer'
        }}
      >
        <StyledQuestion width={7 / 8}>{question}</StyledQuestion>
        <Image
          src={chevronDown}
          alt="chevron"
          height="24px"
          width="24px"
          style={{
            transition: 'transform 300ms',
            transform: display ? 'rotateZ(180deg)' : 'rotateZ(0deg)'
          }}
        ></Image>
      </Flex>
      <StyledText
        style={{
          display: display ? 'block' : 'none',
          transition: 'transform 300ms',
          opacity: display ? '1' : '0',
          height: display ? 'auto' : '0'
        }}
      >
        <ReactMarkdown
          source={answer}
          renderers={{ link: MarkdownLinkRenderer }}
        />
      </StyledText>
    </StyledBox>
  );
};

export default Dropdown;

import React, { useEffect } from 'react';
import Layout from '../components/Layout';
import Challenges from '../sections/Challenges';
import AboutTheQuiz from '../sections/AboutTheQuiz';
import GettingDiagnosedWith from '../sections/GettingDiagnosedWith';
import CheckRisksAndSymptoms from '../sections/CheckRisksAndSymptoms';
import { STORAGE_KEYS } from '../resources/constants';
import { patientsService } from '../services/services';

const IndexPage = () => {
  const getId = () => {
    const queryString = window.location.search.substr(1);
    const data = {
      urlParams: queryString
    };

    patientsService(data)
      .then(response => {
        localStorage.setItem(STORAGE_KEYS.ID, response.data.id);
        localStorage.getItem(STORAGE_KEYS.QUIZ_COMPLETE, false);
      })
      .catch(error => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (!localStorage.getItem(STORAGE_KEYS.ID)) getId();
  }, []);

  return (
    <Layout logoHeader={true} mainHeader={true}>
      <Challenges />
      <AboutTheQuiz />
      <CheckRisksAndSymptoms />
      <GettingDiagnosedWith />
    </Layout>
  );
};

export default IndexPage;

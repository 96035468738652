import React, { useState, useEffect } from 'react';
import { Flex, Button, Text, Heading } from 'rebass/styled-components';
import { Link as GatsbyLink } from 'gatsby';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';
import { STORAGE_KEYS } from '../resources/constants';

const StyledHeader = styled(Heading)`
  font-family: Poppins;
  font-size: 28px;
  font-weight: bold;
  color: #2f261e;
`;

const StyledText = styled(Text)`
  font-family: Poppins;
  font-size: 20px;
  font-weight: bold;
  line-height: 1.1;
  letter-spacing: normal;
  color: ${({ theme }) => theme.colors.black};
`;

const CheckRisksAndSymptoms = () => {
  const [quizComplete, setQuizComplete] = useState(null);

  const data_content = useStaticQuery(
    graphql`
      query MyQueryCheckRisksAndSymptoms {
        contentfulAoNLandingPageCheckYourRisks {
          title
          content {
            content
          }
          button {
            text
            url
            urlInternal
          }
          secondaryButton {
            text
            url
            urlInternal
          }
        }
      }
    `
  );

  const handleTrackCustomEvent = actionName => {
    trackCustomEvent({
      category: 'homePage checkRisksAndSymptoms',
      action: actionName
    });
  };

  useEffect(() => {
    setQuizComplete(localStorage.getItem(STORAGE_KEYS.QUIZ_COMPLETE));
  }, []);

  return (
    <Flex
      flexDirection="column"
      justifyContent="flex-start"
      maxWidth="550px"
      mt={11}
      mb={8}
    >
      <StyledHeader mx={3} mt={5} mb={2}>
        {data_content.contentfulAoNLandingPageCheckYourRisks.title}
      </StyledHeader>
      {!quizComplete && (
        <GatsbyLink
          to={
            data_content.contentfulAoNLandingPageCheckYourRisks.button
              .urlInternal
              ? data_content.contentfulAoNLandingPageCheckYourRisks.button
                  .urlInternal
              : data_content.contentfulAoNLandingPageCheckYourRisks.button.url
          }
          target={
            data_content.contentfulAoNLandingPageCheckYourRisks.button
              .urlInternal
              ? ''
              : '_blank'
          }
        >
          <Button
            variant="bRed"
            my={5}
            width="228px"
            onClick={() =>
              handleTrackCustomEvent(
                data_content.contentfulAoNLandingPageCheckYourRisks.button.text
              )
            }
          >
            {data_content.contentfulAoNLandingPageCheckYourRisks.button.text}
          </Button>
        </GatsbyLink>
      )}
      {quizComplete && (
        <GatsbyLink
          to={
            data_content.contentfulAoNLandingPageCheckYourRisks.secondaryButton
              .urlInternal
              ? data_content.contentfulAoNLandingPageCheckYourRisks
                  .secondaryButton.urlInternal
              : data_content.contentfulAoNLandingPageCheckYourRisks
                  .secondaryButton.url
          }
          target={
            data_content.contentfulAoNLandingPageCheckYourRisks.secondaryButton
              .urlInternal
              ? ''
              : '_blank'
          }
        >
          <Button
            variant="bRed"
            my={5}
            width="228px"
            onClick={() =>
              handleTrackCustomEvent(
                data_content.contentfulAoNLandingPageCheckYourRisks
                  .secondaryButton.text
              )
            }
          >
            {
              data_content.contentfulAoNLandingPageCheckYourRisks
                .secondaryButton.text
            }
          </Button>
        </GatsbyLink>
      )}
    </Flex>
  );
};

export default CheckRisksAndSymptoms;

import React from 'react';
import { Box } from 'rebass/styled-components';

const Line = ({ height, color, my, mb, mt }) => {
  return (
    <Box width="100%" height={height} bg={color} my={my} mt={mt} mb={mb}></Box>
  );
};

export default Line;

import React from 'react';
import { Box } from 'rebass/styled-components';
import styled from 'styled-components';

const StyledText = styled(Box)`
  font-family: Poppins;
  font-size: 16px;
  line-height: 1.5;
`;

const CardText = ({ children, mx, my, color, className }) => {
  return (
    <StyledText mx={mx} my={my} color={color} className={className}>
      {children}
    </StyledText>
  );
};

export default CardText;
